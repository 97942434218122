export const INSTA_WORKPLATFORM_ID = "9bb8913b-ddd9-430b-a66a-d74d846e6c66";
export const BEEHIIV_WORKPLATFORM_ID = "24fcaa09-0a79-4deb-b074-d8efd900265b";
export const LINKEDIN_WORKPLATFORM_ID = "36410629-f907-43ba-aa0d-434ca9c0501a";
export const FACEBOOK_WORKPLATFORM_ID = "ad2fec62-2987-40a0-89fb-23485972598c";
export const INSTA_WORKPLATFORM_NAME = "Instagram";
export const FACEBOOK_WORKPLATFORM_NAME = "Facebook";

// Callbacks
export const PHYLLO_ACCOUNT_CONNECTED_KEY = "accountConnected";
export const PHYLLO_ACCOUNT_DISCONNECTED_KEY = "accountDisconnected";
export const PHYLLO_ON_TOKEN_EXPIRED_KEY = "tokenExpired";
export const PHYLLO_ON_EXIT_KEY = "exit";
export const PHYLLO_ON_CONNECTION_FAILURE = "connectionFailure";

// Redirect query params
export const PHYLLO_CONNECTION_SUCCESSFUL = "SUCCESS";
export const PHYLLO_CONNECTION_UNSUCCESSFUL = "FAILED";

export const PHYLLO_CONNECTION_STATUS = "phyllo_status";
export const PHYLLO_USER_ID = "phyllo_user_id";
export const PHYLLO_userId = "phyllo_user_id";
export const PHYLLO_CONNECTION_INFO = "phyllo_accounts_connected";
export const PHYLLO_DISCONNECTION_INFO = "phyllo_accounts_disconnected";
export const PHYLLO_REASON = "phyllo_exit_reason";

export const PHYLLO_CONNECTED_ACCOUNT = "CONNECTED";
export const PHYLLO_DISCONNECTED_ACCOUNT = "NOT_CONNECTED";
export const PHYLLO_SESSION_EXPIRED_ACCOUNT = "SESSION_EXPIRED";

export const PHYLLO_EXIT_REASON_TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const PHYLLO_EXIT_REASON_BACK_PRESSED = "BACK_PRESSED";
export const PHYLLO_EXIT_REASON_EXIT_FROM_PLATFORM_SELECTION = "EXIT_FROM_PLATFORM_SELECTION";
export const PHYLLO_EXIT_REASON_EXIT_FROM_PLATFORM = "EXIT_FROM_PLATFORM_";
export const PHYLLO_EXIT_REASON_EXIT_FROM_CONNECTION_FAILED = "EXIT_FROM_CONNECTION_FAILED";
export const PHYLLO_EXIT_REASON_DONE_CLICKED = "DONE_CLICKED";

export const POLLING_SHORT_INTERVAL_VALUE = 3000;
export const POLLING_LONG_INTERVAL_VALUE = 6000;

// Login Types
export const NATIVE_LOGIN = "NATIVE_LOGIN";
export const SCRAPER_LOGIN_PROGRESS_STATES = ["FFA_IN_PROGRESS", "MFA_IN_PROGRESS", "CAPTCHA_IN_PROGRESS", "CAPTCHA_REQUIRED"];

// Types of environments
export const environmentConfig = ["production", "sandbox", "staging", "dev", "dev2", "dev3", "dev4", "preprod"];

// API Enpoint Config
export const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT;

// Languages
export const LANGUAGES = {
    english: ["en"],
    indonesian: ["id"],
    japanese: ["ja"],
    chinese: ["cn", "zh"],
    french: ["fr"],
    chinese_traditional: ["zh-tw"],
    spanish: ["es"],
    portuguese: ["pt"],
};

export const LANGUAGES_AVAILABLE = ["en", "id", "ja", "cn", "zh", "fr", "zh-tw", "es", "pt"];

export const SDK_OAUTH_LOADER_MESSAGE_TITLE = {
    en: "Your {{platform}} login screen is open in another window.",
    id: "Layar login {{platform}} akan terbuka di jendela terpisah. Klik di sini untuk melanjutkan masuk.",
    ja: "{{platform}} のログイン画面が別ウィンドウで開きます。ログインを続ける場合はこちらをクリックしてください。",
    cn: "您的 {{platform}} 登录界面已在另一个窗口中打开。",
    zh: "您的 {{platform}} 登录界面已在另一个窗口中打开。",
    fr: "Votre écran de connexion {{platform}} s'ouvre dans une autre fenêtre.",
    "zh-tw": "您的 {{platform}} 登入畫面已在另一個視窗中開啟。",
    es: "Su pantalla de inicio de sesión de {{platform}} está abierta en otra ventana.",
    pt: "Sua tela de login da {{platform}} está aberta em outra janela.",
};

export const SDK_OAUTH_LOADER_MESSAGE_LINK_TEXT = {
    en: "Click here to continue with the login.",
    id: "Klik di sini untuk melanjutkan login.",
    ja: "ログインを続ける場合はこちらをクリックしてください。",
    cn: "单击此处继续登录。",
    zh: "单击此处继续登录。",
    fr: "Cliquez ici pour continuer la connexion.",
    "zh-tw": "按一下此處繼續登入。",
    es: "Haga clic aquí para continuar con el inicio de sesión.",
    pt: "Clique aqui para continuar com o login.",
};

export const PHYLLO_API_MAX_ATTEMPTS = 30;
export const PHYLLO_API_POLLING_INTERVAL = 1000;
export const PHYLLO_API_RETRY_ERROR_CODES = [429, 500, 502, 503, 504];
export const FACEBOOK_HELP_GUIDE_URL = "https://www.facebook.com/business/help/1199464373557428?id=418112142508425";
